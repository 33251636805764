import React, { Component } from "react";
import { Redirect } from "react-router";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Ink from "react-ink";
import DelayLink from "../../helpers/delayLink";


class Footer extends Component {
	constructor() {
		super();
		
	}

	state = {
		active_home: false,
		active_products: false,
		active_explore: false,
		active_cart: false,
		active_account: false,
		active_alerts: false,
		unread_alerts_count: null,
		error: false,
		agree: false,
		email:'',
		shownewsletter: true
	};

	

	componentDidMount() {
		
		if (this.props.active_home === true) {
			this.setState({ active_home: true });
		}
		if (this.props.active_products === true) {
			this.setState({ active_products: true });
		}
		if (this.props.active_explore === true) {
			this.setState({ active_explore: true });
		}
		if (this.props.active_cart === true) {
			this.setState({ active_cart: true });
		}
		if (this.props.active_account === true) {
			this.setState({ active_account: true });
		}
		if (this.props.active_alerts === true) {
			this.setState({ active_alerts: true });
		}
		
	}


	render() {
		const { cartTotal, alertUnreadTotal, newsletter_response, get_category, get_brand } = this.props;
	
		return (
			<React.Fragment>
			

				 
				
			

				<footer>

				
				<div className="footer-top container">
				  <div className="row  justify-content-md-center">
					 <div className="col-md-2 col-sm-2">
						<div className="footer-logo">
							<img src="/assets/img/logos/logo.png" className="logo" alt="logo" />

						
						
						</div>
					
						
					</div> 
					<div className="col-md-3 col-sm-4">
					<ul className="footer-address">
							<li> <span className="mob-icon"><i className="bi bi-geo-alt-fill"></i></span><span className="link-lable"> </span>150, Changi Road, #01-02 Guthrie Building, Singapore 419973</li>
					<li> <a href="tel:+6563454565"> <span className="mob-icon"><i className="bi bi-telephone"></i></span><span className="desk-icon"><span className="link-lable">Call us:</span>(65) 63454565</span></a>  </li>
				 <li> <a href="tel:+6596274977"> <span className="mob-icon"><i className="fa fa-whatsapp"></i></span><span className="desk-icon"><span className="link-lable">Whatsapp Us:</span>(65) 96274977</span></a></li>
				<li><a href="https://facebook.com/byspajelita" target="_blank"> <span className="mob-icon"><i className="bi bi-facebook"></i></span> <span className="desk-icon"><span className="link-lable">Visit Us On:</span> Facebook</span></a></li>
				
						</ul>
					</div>
				  
					<div className="col-md-4 col-sm-3">
							<ul className="footer-menu">

							<li><DelayLink to="/about">About Us</DelayLink></li>
							<li><DelayLink to="/contact-us">Contact Us </DelayLink></li>
						{/*	<li><DelayLink to="">Spa Treatment</DelayLink></li>
							<li><DelayLink to="">Shop</DelayLink></li> */}
							<li><DelayLink to="/services">Services</DelayLink></li>
							<li><DelayLink to="/products">Products</DelayLink></li>
							<li><DelayLink to="/privacy-policy">Privacy Policy</DelayLink></li> 
							<li><DelayLink to="/terms-condition">Terms and Conditions</DelayLink></li>
							

							</ul>
					</div> 
					

					<div className="col-md-3 col-sm-3">
					<div className="fac-box">
						<a href="/help-center">
						  <div className="fac-icon">
							<i className="bi bi-headset"></i>
						  </div>
						  <div className="facilty-title">
							Help Center
						  </div>
						  </a>
						</div>

						<div className="fac-box">
						<a href="/enquiry">
						  <div className="fac-icon">
							<i className="bi bi-envelope"></i>
						  </div>
						  <div className="facilty-title">
							Message Us
						  </div>
						  </a>
						</div>

						
						<div className="fac-box">
						<a href="https://apps.apple.com/us/app/spajelita/id1618176703" target="blank">
						 
						  <div className="app-store-link">
							<img src="/assets/img/icons/app_store.png"/>
						  </div>
						  </a>
						</div>

						<div className="fac-box">
						<a href="https://play.google.com/store/apps/details?id=com.spajelita.app" target="blank">
						 
						  <div className="playstore-link">
							<img src="/assets/img/icons/google_play.png"/>
						  </div>
						  </a>
						</div>
					
						</div>
				  </div>

				  
				
				  </div>
<div className="footer-copyright">
<p className="footer-copyright-txt">Copyright © 2021 SpaJelita.com  -  All Rights Reserved</p>

</div>

<div className="d-block d-sm-none">
				
					<div className="content pt-10 font-size-xs clearfix footer-fixed">
					
					<NavLink to="/home" className="col footer-links">
							
							<i className="bi bi-house mob-menu-icon" /> <br />
							<span className={this.state.active_home ? "active-footer-tab" : ""}>
								{this.state.active_home ? (
									localStorage.getItem("footerAlerts")
								) : (
									<span className="  mob-menu-txt"> Home</span>
								)}
							</span>
						</NavLink>
			
					<NavLink to="/services" className="col footer-links">
							
							<i className="bi bi-menu-button mob-menu-icon" /> <br />
							<span className={this.state.active_alerts ? "active-footer-tab" : ""}>
								{this.state.active_alerts ? (
									localStorage.getItem("footerAlerts")
								) : (
									<span className="  mob-menu-txt"> Service</span>
								)}
							</span>
						</NavLink> 
						<NavLink to="/products" className="col footer-links">
							
							<i className="bi bi-shop mob-menu-icon" /> <br />
							<span className={this.state.active_products ? "active-footer-tab" : ""}>
								{this.state.active_products ? (
									localStorage.getItem("footerAlerts")
								) : (
									<span className="  mob-menu-txt"> Shop</span>
								)}
							</span>
						</NavLink>
				
						<NavLink to="/cart" className="col footer-links">
							<i className="bi bi-basket  mob-menu-icon" /> <br />
							<span className={this.state.active_cart ? "active-footer-tab" : ""}>
								{this.state.active_cart
									? localStorage.getItem("footerCart")
									: <span className="  mob-menu-txt"> Cart</span>}
								<span
									className="cart-quantity-badge"
									style={{ backgroundColor: localStorage.getItem("storeColor") }}
								>
									{cartTotal.productQuantity}
								</span>
							</span>
						</NavLink>
						<NavLink to="/my-account" className="col footer-links">
							<i className="bi bi-person  mob-menu-icon" /> <br />
							<span className={this.state.active_account ? "active-footer-tab" : ""}>
								{this.state.active_account
									? <span className="  mob-menu-txt">  Login / Register</span>
									: <span className="  mob-menu-txt">  My Account</span>}
							</span>
						</NavLink>
					</div>
				</div>
				
				</footer>
				
			</React.Fragment>

		);
	}
}

const mapStateToProps = state => ({
	cartTotal: state.total.data,
});

export default connect(mapStateToProps, {})(Footer);
