import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";

import { CATEGORY_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class TopShoppingOffers extends Component {
    constructor(props){
		super(props);
		
	}
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { offers} = this.props;
		
		return (                        
			<React.Fragment>
                         
<section className="slide-wrapper top-offers">
    <div className="slide-holder container">
	  <div className="slider-title">
		<h4 className="title-head">Top Shopping Offers</h4>
		<div className="title-options">
		  
		</div>

	  </div>
	  
      <ul className="top-offers-list">
		{(offers.map((item, index) =>  
			<React.Fragment key={index}>  
				<li  key={index}>
				<DelayLink to={"/category/"+item.subcategory_id} delay={50} >
					<div className="grid-card">  
						<div className="grid-img-holder">
						
							<img src={CATEGORY_IMAGE_URL + item.child_cat_image} alt={item.offer_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
					
						</div>
					
					</div>	
					<div className="item-name">{item.offer_name}</div>
					</DelayLink>						   
				</li>									
			</React.Fragment>
		))}
      </ul>
    
  </div>
</section>						 
                
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(TopShoppingOffers);