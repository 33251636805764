import {
	GET_RESTAURANT_INFO,
	GET_RESTAURANT_INFO_BY_ID,
	GET_RESTAURANT_ITEMS,
	RESET_INFO,
	RESET_ITEMS,
	SINGLE_ITEM,
	SEARCH_ITEM,
	CLEAR_SEARCH,
	GET_CATEGORY,
	GET_SINGLE_CATEGORY_ITEMS,
	GET_BRAND,
	GET_CATEGORY_HOME,
	TOP_SHOPPING_OFFERS,
	SHOP_OUR_SERVICES,
	TODAY_DEALS,
	FASHION_OFFERS,
	FEATURED_PRODUCTS,
	TOP_OF_THE_DAY,
	GET_PRODUCT_BY_CATEGORY,
} from "./actionTypes";
import {
	GET_RESTAURANT_INFO_BY_ID_URL,
	GET_RESTAURANT_INFO_URL,
	GET_RESTAURANT_ITEMS_URL,
	GET_SINGLE_ITEM_URL,
	GET_CATEGORY_URL,
	GET_SINGLE_CATEGORY_ITEMS_URL,
	GET_BRAND_URL,
	GET_CATEGORY_HOME_URL,
	GET_CATE_URL,
	TOP_SHOPPING_OFFERS_URL,
	SHOP_OUR_SERVICES_URL,
	TODAY_DEALS_URL,
	FASHION_OFFERS_URL,
	FEATURED_PRODUCTS_URL,
	TOP_OF_THE_DAY_URL,
	GET_PRODUCT_BY_CATEGORY_URL, keyValue
} from "../../configs";

import Axios from "axios";

export const getCategory = () => (dispatch) => {
	return Axios.get(GET_CATE_URL )
		.then((response) => {
			const get_category = response.data;
			return dispatch({ type: GET_CATEGORY, payload: get_category });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const getCategoryHome = () => (dispatch) => {
	return Axios.get(GET_CATEGORY_HOME_URL )
		.then((response) => {
			const get_category_home = response.data;
			return dispatch({ type: GET_CATEGORY_HOME, payload: get_category_home });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const getBrand = () => (dispatch) => {
	return Axios.get(GET_BRAND_URL )
		.then((response) => {
			const get_brand = response.data;
			return dispatch({ type: GET_BRAND, payload: get_brand });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const topShoppingOffers = () => (dispatch) => {
	return Axios.get(TOP_SHOPPING_OFFERS_URL )
		.then((response) => {
			const top_shopping_offers = response.data;
			return dispatch({ type: TOP_SHOPPING_OFFERS, payload: top_shopping_offers });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const shopOurServices = () => (dispatch) => {
	return Axios.get(SHOP_OUR_SERVICES_URL )
		.then((response) => {
			const shop_our_services = response.data;
			return dispatch({ type: SHOP_OUR_SERVICES, payload: shop_our_services });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const todayDeals = () => (dispatch) => {
	return Axios.get(TODAY_DEALS_URL )
		.then((response) => {
			const today_deals = response.data;
			return dispatch({ type: TODAY_DEALS, payload: today_deals });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const fashionOffers = () => (dispatch) => {
	return Axios.get(FASHION_OFFERS_URL )
		.then((response) => {
			const fashion_offers = response.data;
			return dispatch({ type: FASHION_OFFERS, payload: fashion_offers });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const featuredProducts = () => (dispatch) => {
	return Axios.post(FEATURED_PRODUCTS_URL )
		.then((response) => {
			const featured_products = response.data;
			return dispatch({ type: FEATURED_PRODUCTS, payload: featured_products });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const topOfTheDay = () => (dispatch) => {
	return Axios.get(TOP_OF_THE_DAY_URL )
		.then((response) => {
			const top_of_the_day = response.data;
			return dispatch({ type: TOP_OF_THE_DAY, payload: top_of_the_day });
		})
		.catch(function(error) {
			console.log(error);
		});
};
export const getSingleCategoryItems = (slug) => (dispatch) => {
	return Axios.post(GET_SINGLE_CATEGORY_ITEMS_URL + "/" + slug)
		.then((response) => {
			const get_single_category_items = response.data;
			return dispatch({ type: GET_SINGLE_CATEGORY_ITEMS, payload: get_single_category_items });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getProductByCategory = (slug) => (dispatch) => {
	Axios.defaults.headers.common = {
          "X-API-Key": keyValue,
        };
	Axios.post(GET_PRODUCT_BY_CATEGORY_URL , {			
			category_id: slug,
		}).then((response) => {
			const get_product_by_category = response.data;
			return dispatch({ type: GET_PRODUCT_BY_CATEGORY, payload: get_product_by_category });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantInfo = (slug) => (dispatch) => {
	return Axios.post(GET_RESTAURANT_INFO_URL + "/" + slug)
		.then((response) => {
			const restaurant_info = response.data;
			return dispatch({ type: GET_RESTAURANT_INFO, payload: restaurant_info });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantInfoById = (id) => (dispatch) => {
	Axios.post(GET_RESTAURANT_INFO_BY_ID_URL + "/" + id)
		.then((response) => {
			const restaurant_info = response.data;
			return dispatch({
				type: GET_RESTAURANT_INFO_BY_ID,
				payload: restaurant_info,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantItems = (slug) => (dispatch) => {
	Axios.post(GET_RESTAURANT_ITEMS_URL + "/" + slug)
		.then((response) => {
			const restaurant_items = response.data;
			return dispatch({ type: GET_RESTAURANT_ITEMS, payload: restaurant_items });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getSingleItem = (id) => (dispatch) => {
	Axios.post(GET_SINGLE_ITEM_URL, {
		id: id,
	})
		.then((response) => {
			const item = response.data;
			return dispatch({ type: SINGLE_ITEM, payload: item });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const resetItems = () => (dispatch) => {
	const empty = [];
	return dispatch({ type: RESET_ITEMS, payload: empty });
};

export const resetInfo = () => (dispatch) => {
	const empty = [];
	return dispatch({ type: RESET_INFO, payload: empty });
};

export const searchItem = (itemList, itemName, searchFoundText, noResultText) => (dispatch, getState) => {
	const searchResultText = searchFoundText + itemName;
	const noSearchFoundText = noResultText + itemName;
	// console.log(JSON.stringify(getState().items.restaurant_items.items));

	// const state = getState().items.restaurant_items.items;
	let arr = [];
	let foodItems = [];
	if (itemList && [itemList].length >= 0) {
		Object.keys(itemList).forEach((keys) => {
			itemList[keys].forEach((itemsList) => {
				arr.push(itemsList);
				foodItems = arr.filter((product) => {
					return product.name.toLowerCase().indexOf(itemName.toLowerCase()) !== -1;
				});
			});
		});
	}
	if (foodItems.length > 0) {
		return dispatch({
			type: SEARCH_ITEM,
			payload: { items: { [searchResultText]: foodItems } },
		});
	} else if (foodItems.length <= 0) {
		return dispatch({
			type: SEARCH_ITEM,
			payload: { items: { [noSearchFoundText]: foodItems } },
		});
	}
};

export const clearSearch = (data) => (dispatch) => {
	return dispatch({ type: CLEAR_SEARCH, payload: data });
};
