import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { CATEGORY_IMAGE_URL, DEFAULT_IMG, SUB_CATEGORY_IMAGE_URL  } from "../../../../configs/index";

class FashionOffers extends Component {

	
    constructor(props){
		super(props);

		this.state = {
            update: true,
        responsive:{
            320: {
                items: 2,
            },
            575: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1024: {
                items: 3,
            },
            1199: {
                items: 3,
            },
                },
            }
    }

  
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { offers} = this.props;
		
		return (                        
			<React.Fragment>                         
				<div className="slide-wrapper offers col-12 col-lg-8 col-md-8">
					<div className="slide-holder">
					  <div className="slider-title">
						<h4 className="title-head">Special Offers</h4>
						<div className="title-options">
						  
						</div>

					  </div>
					  
					  <ul className="top-offers-list">

					  <OwlCarousel
                    className="owl-theme mt-20"
					nav={true}
					autoplay
					loop={false}
					dots={false}
					items={1} 
					rewind={true} 
                    responsive={this.state.responsive}                    
                >
                {offers.length === 0 ? (
						<ContentLoader
							height={170}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
							
						</ContentLoader>
                ) : ( offers.map((item, index) =>  
							
								<li   key={item.id}>
										<DelayLink to={"/category/"+item.subcategory_id} delay={50} >
									<div className="grid-card">  
										<div className="grid-img-holder">
									
											<img src={SUB_CATEGORY_IMAGE_URL + item.child_cat_image} alt={item.child_cat_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
										
										</div>
										<div className="item-name">{item.child_cat_name}</div>
									</div>	
									</DelayLink>						   
								</li>									
							
						))}
						</OwlCarousel>
					  </ul>
					
				  </div>
				</div>		              
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(FashionOffers);