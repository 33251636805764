export const GET_RESTAURANT_INFO = "GET_RESTAURANT_INFO";
export const GET_RESTAURANT_INFO_BY_ID = "GET_RESTAURANT_INFO_BY_ID";
export const GET_RESTAURANT_ITEMS = "GET_RESTAURANT_ITEMS";
export const RESET_ITEMS = "RESET_ITEMS";
export const RESET_INFO = "RESET_INFO";
export const SINGLE_ITEM = "SINGLE_ITEM";

export const SEARCH_ITEM = "SEARCH_ITEM";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SINGLE_CATEGORY_ITEMS = "GET_SINGLE_CATEGORY_ITEMS";
export const GET_BRAND = "GET_BRAND";
export const GET_CATEGORY_HOME = "GET_CATEGORY_HOME";

export const TOP_SHOPPING_OFFERS = "TOP_SHOPPING_OFFERS";
export const SHOP_OUR_SERVICES = "SHOP_OUR_SERVICES";
export const TODAY_DEALS = "TODAY_DEALS";
export const FASHION_OFFERS = "FASHION_OFFERS";
export const FEATURED_PRODUCTS = "FEATURED_PRODUCTS";
export const TOP_OF_THE_DAY = "TOP_OF_THE_DAY";

export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY";