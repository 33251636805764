import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";

import { CATEGORY_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class TopOfTheDay extends Component {
    constructor(props){
		super(props);
		
	}
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { offers} = this.props;
		// console.log(offers);
		return (                        
			<React.Fragment>
                         
				<section className="slide-wrapper top-ofthe-day">
				  <div className="slide-holder container">
					<div className="slider-title">
						<h4 className="title-head">Top of the Day</h4>
						<div className="title-options">
						</div>
					</div>
					<ul className="top-ofthe-day-list">
					{(offers.map((item, index) =>  
					  <li key={index}>
						<DelayLink to={"/category/"+item.subcategory_id} delay={50} > 
						{/* <div className="grid-img-holder">
									
											<img src={item.child_cat_image} alt={item.child_cat_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
									
										</div>       */}
							<div className="item-name">{item.child_cat_name}</div>
						</DelayLink>
					  </li>
					))}
					</ul>
				  
				</div>
				</section>
				                 
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(TopOfTheDay);