import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Loadable from "react-loadable";
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import withTracker from "./withTracker";

// import NotFound from "./components/NotFound";
const NotFound = Loadable({
	loader: () => import("./components/NotFound"),
	loading: () => <Loading />,
});

// import Location from "./components/Mobile/Location";
const Location = Loadable({
	loader: () => import("./components/Mobile/Location"),
	loading: () => <Loading />,
});

// import Items from "./components/Mobile/Items";
const Items = Loadable({
	loader: () => import("./components/Mobile/Items"),
	loading: () => <Loading />,
});

// import Login from "./components/Mobile/Auth/Login";
const Login = Loadable({
	loader: () => import("./components/Mobile/Auth"),
	loading: () => <Loading />,
});

// import Register from "./components/Mobile/Auth/Register";
const Register = Loadable({
	loader: () => import("./components/Mobile/Auth/Register"),
	loading: () => <Loading />,
});

// import CartPage from "./components/Mobile/Cart";
const CartPage = Loadable({
	loader: () => import("./components/Mobile/Cart"),
	loading: () => <Loading />,
});

// import Account from "./components/Mobile/Account";
const Account = Loadable({
	loader: () => import("./components/Mobile/Account"),
	loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const Explore = Loadable({
	loader: () => import("./components/Mobile/Explore"),
	loading: () => <Loading />,
});

// import Addresses from "./components/Mobile/Account/Addresses";
const Addresses = Loadable({
	loader: () => import("./components/Mobile/Account/Addresses"),
	loading: () => <Loading />,
});

// import Checkout from "./components/Mobile/Checkout";
const Checkout = Loadable({
	loader: () => import("./components/Mobile/Checkout"),
	loading: () => <Loading />,
});

// import RunningOrder from "./components/Mobile/RunningOrder";
const RunningOrder = Loadable({
	loader: () => import("./components/Mobile/RunningOrder"),
	loading: () => <Loading />,
});

// import Orders from "./components/Mobile/Account/Orders";
const Orders = Loadable({
	loader: () => import("./components/Mobile/Account/Orders"),
	loading: () => <Loading />,
});

// import WalletPage from "./components/Mobile/Account/Wallet";
const WalletPage = Loadable({
	loader: () => import("./components/Mobile/Account/Wallet"),
	loading: () => <Loading />,
});
/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = Loadable({
	loader: () => import("./components/Delivery"),
	loading: () => <Loading />,
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = Loadable({
	loader: () => import("./components/Delivery/Login"),
	loading: () => <Loading />,
});

// import DeliveryOrders from "./components/Delivery/Orders";
const DeliveryOrders = Loadable({
	loader: () => import("./components/Delivery/Orders"),
	loading: () => <Loading />,
});

// import ViewOrder from "./components/Delivery/ViewOrder";
const ViewOrder = Loadable({
	loader: () => import("./components/Delivery/ViewOrder"),
	loading: () => <Loading />,
});

// import GeoLocationPage from "./components/Mobile/GeoLocationPage";
const GeoLocationPage = Loadable({
	loader: () => import("./components/Mobile/GeoLocationPage"),
	loading: () => <Loading />,
});

// import SingleItem from "./components/Mobile/Items/SingleItem";
const SingleItem = Loadable({
	loader: () => import("./components/Mobile/Items/SingleItem"),
	loading: () => <Loading />,
});

const SinglePage = Loadable({
	loader: () => import("./components/SinglePage"),
	loading: () => <Loading />,
});

const ForgotPassword = Loadable({
	loader: () => import("./components/Mobile/Auth/ForgotPassword"),
	loading: () => <Loading />,
});

const RestaurantListOnCategory = Loadable({
	loader: () => import("./components/Mobile/Home/RestaurantListOnCategory"),
	loading: () => <Loading />,
});

const RatingAndReview = Loadable({
	loader: () => import("./components/Mobile/Account/Orders/RatingAndReview"),
	loading: () => <Loading />,
});

const Alerts = Loadable({
	loader: () => import("./components/Mobile/Alerts"),
	loading: () => <Loading />,
});

// import Enquiry from "./components/Mobile/Auth/Enquiry";
const Enquiry = Loadable({
	loader: () => import("./components/Mobile/Auth/Enquiry"),
	loading: () => <Loading />,
});
const ItemDetails = Loadable({
	loader: () => import("./components/Mobile/ItemDetails"),
	loading: () => <Loading />,
});
const Gift = Loadable({
	loader: () => import("./components/Mobile/Gift"),
	loading: () => <Loading />,
});
const Brand = Loadable({
	loader: () => import("./components/Mobile/Brand"),
	loading: () => <Loading />,
});
const Category = Loadable({
	loader: () => import("./components/Mobile/Category"),
	loading: () => <Loading />,
});

const ProductRequest = Loadable({
	loader: () => import("./components/Mobile/ProductRequest"),
	loading: () => <Loading />,
});
const ReferFriend = Loadable({
	loader: () => import("./components/Mobile/ReferFriend"),
	loading: () => <Loading />,
});
const ReferFriendHome = Loadable({
	loader: () => import("./components/Mobile/ReferFriendHome"),
	loading: () => <Loading />,
});
const ExpressDelivery = Loadable({
	loader: () => import("./components/Mobile/ExpressDelivery"),
	loading: () => <Loading />,
});
const Services = Loadable({
	loader: () => import("./components/Mobile/Services"),
	loading: () => <Loading />,
});
const ServiceLists = Loadable({
	loader: () => import("./components/Mobile/ServiceLists"),
	loading: () => <Loading />,
});
const ServiceDetails = Loadable({
	loader: () => import("./components/Mobile/ServiceDetails"),
	loading: () => <Loading />,
});
const Payment = Loadable({
	loader: () => import("./components/Mobile/Payment"),
	loading: () => <Loading />,
});
const Courses = Loadable({
	loader: () => import("./components/Mobile/Courses"),
	loading: () => <Loading />,
});
const Gallery = Loadable({
	loader: () => import("./components/Mobile/GalleryPage"),
	loading: () => <Loading />,
});
const MyAccount = Loadable({
	loader: () => import("./components/Mobile/Account/Profile"),
	loading: () => <Loading />,
});
const Rebooking = Loadable({
	loader: () => import("./components/Mobile/Rebooking"),
	loading: () => <Loading />,
});

const HelpCenter = Loadable({
	loader: () => import("./components/Mobile/HelpCenter"),
	loading: () => <Loading />,
});
const Tickets = Loadable({
	loader: () => import("./components/Mobile/Tickets"),
	loading: () => <Loading />,
});
const Redemption = Loadable({
	loader: () => import("./components/Mobile/Redemption"),
	loading: () => <Loading />,
});
const About = Loadable({
	loader: () => import("./components/Mobile/About"),
	loading: () => <Loading />,
});
const ContactUs = Loadable({
	loader: () => import("./components/Mobile/ContactUs"),
	loading: () => <Loading />,
});
const TermsAndCondition = Loadable({
	loader: () => import("./components/Mobile/TermsAndCondition"),
	loading: () => <Loading />,
});
const RunningService = Loadable({
	loader: () => import("./components/Mobile/RunningService"),
	loading: () => <Loading />,
});
const PrivacyPolicy = Loadable({
	loader: () => import("./components/Mobile/PrivacyPolicy"),
	loading: () => <Loading />,
});
const Testimonial = Loadable({
	loader: () => import("./components/Mobile/Testimonial"),
	loading: () => <Loading />,
});

const Crown = Loadable({
	loader: () => import("./components/Mobile/Crown"),
	loading: () => <Loading />,
});
const Promotion = Loadable({
	loader: () => import("./components/Mobile/Promotion"),
	loading: () => <Loading />,
});
const Facility = Loadable({
	loader: () => import("./components/Mobile/Facility"),
	loading: () => <Loading />,
});
const GiftVoucher = Loadable({
	loader: () => import("./components/Mobile/GiftVoucher"),
	loading: () => <Loading />,
});

// const TestComponent = Loadable({
// 	loader: () => import("./components/Mobile/TestComponent"),
// 	loading: () => <Loading />
// });

const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
};

ReactDOM.render(
	<Root>
		<BrowserRouter>
			<React.Fragment>
				<Route component={ScrollToTop} />
				<Switch>
					{/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
					<Route path={"/"} exact component={withTracker(App)} />

					{/* Test Routes*/}
					{/* <Route path={"/saurabh/test"} exact component={TestComponent} /> */}
					<Route path={"/products"} exact component={withTracker(Category)} />
					<Route path={"/products/:category"} exact component={withTracker(Items)} />
					<Route path={"/productdetails/:id"} exact component={withTracker(ItemDetails)} />
					
					<Route path={"/brands/:id"} exact component={withTracker(Brand)} />
					<Route path={"/category/:id"} exact component={withTracker(Category)} />
					
					<Route path={"/deals/:product_id"} exact component={withTracker(Brand)} />

					<Route path={"/login"} exact component={withTracker(Login)} />
					<Route path={"/register"} exact component={withTracker(Register)} />
					<Route path={"/cart"} exact component={withTracker(CartPage)} />
					<Route path={"/checkout"} exact component={withTracker(Checkout)} />
					<Route path={"/payment"} exact component={withTracker(Payment)} />
					
					<Route path={"/courses"} exact component={withTracker(Courses)} />
					<Route path={"/gallery"} exact component={withTracker(Gallery)} />
					<Route path={"/facility"} exact component={withTracker(Facility)} />
					<Route path={"/promotion"} exact component={withTracker(Promotion)} />
					<Route path={"/gift-voucher"} exact component={withTracker(GiftVoucher)} />
					
					
					<Route path={"/my-orders"} exact component={withTracker(Account)} />
					<Route path={"/my-account"} exact component={withTracker(MyAccount)} />
					<Route path={"/services"} exact component={withTracker(Services)} />
					<Route path={"/services/:id"} exact component={withTracker(ServiceLists)} />
					<Route path={"/servicedetails/:id"} exact component={withTracker(ServiceDetails)} />
					

					<Route path={"/search-location"} exact component={withTracker(Location)} />
					<Route path={"/my-location"} exact component={withTracker(GeoLocationPage)} />

					<Route path={"/categories/stores"} exact component={withTracker(RestaurantListOnCategory)} />

					<Route path={"/stores/:restaurant"} exact component={withTracker(Items)} />
					<Route path={"/shops"} exact component={withTracker(Items)} />
                    <Route path={"/itemdetails"} exact component={withTracker(ItemDetails)} />
                    <Route path={"/itemdetails/:id"} exact component={withTracker(ItemDetails)} />
					<Route path={"/shops/:category"} exact component={withTracker(Items)} />
					
					<Route path={"/stores/:restaurant/:id"} exact component={withTracker(SingleItem)} />

					<Route path={"/explore"} exact component={withTracker(Explore)} />
					
					<Route path={"/rebooking"} exact component={withTracker(Rebooking)} />

					<Route path={"/enquiry"} exact component={withTracker(Enquiry)} />
					<Route path={"/help-center"} exact component={withTracker(HelpCenter)} />
					<Route path={"/tickets/:id"} exact component={withTracker(Tickets)} />
					<Route path={"/redemption"} exact component={withTracker(Redemption)} />
					<Route path={"/login/forgot-password"} exact component={withTracker(ForgotPassword)} />
					
					
					<Route path={"/about"} exact component={withTracker(About)} />
					<Route path={"/contact-us"} exact component={withTracker(ContactUs)} />
					<Route path={"/terms-condition"} exact component={withTracker(TermsAndCondition)} />
					<Route path={"/privacy-policy"} exact component={withTracker(PrivacyPolicy)} />
					<Route path={"/testimonial"} exact component={withTracker(Testimonial)} />
					
					<Route path={"/running-order/:unique_order_id"} exact component={withTracker(RunningOrder)} />
					<Route path={"/running-appointment/:unique_order_id"} exact component={withTracker(RunningService)} />
					<Route path={"/your-salon"} exact component={withTracker(Crown)} />
					
{/*				
					<Route path={"/alerts"} exact component={withTracker(Alerts)} />
					<Route path={"/my-addresses"} exact component={withTracker(Addresses)} />
					<Route path={"/my-wallet"} exact component={withTracker(WalletPage)} />
					<Route path={"/my-orders"} exact component={withTracker(Orders)} />
					<Route path={"/rate-and-review/:id"} exact component={withTracker(RatingAndReview)} />

					
					<Route path={"/running-order/:unique_order_id"} exact component={withTracker(RunningOrder)} />


					<Route path={"/pages/:slug"} exact component={withTracker(SinglePage)} />
					
					<Route path={"/gift"} exact component={withTracker(Gift)} />
					<Route path={"/brand"} exact component={withTracker(Brand)} />
					<Route path={"/refer-friend"} exact component={withTracker(ReferFriend)} />
					<Route path={"/refer-friend-page"} exact component={withTracker(ReferFriendHome)} />
					<Route path={"/express-delivery"} exact component={withTracker(ExpressDelivery)} />

					{/* Delivery Routes 
					<Route path={"/delivery"} exact component={Delivery} />
					<Route path={"/delivery/login"} exact component={DeliveryLogin} />
					<Route path={"/delivery/orders"} exact component={DeliveryOrders} />
					<Route path={"/delivery/orders/:unique_order_id"} exact component={ViewOrder} />
					<Route path={"/delivery/completed-orders"} exact component={Delivery} />
					*/}
					{/* Product Request 
					<Route path={"/product-request"} exact component={withTracker(ProductRequest)} />*/}
					{/* Common Routes */}
					<Route component={NotFound} />
				</Switch>
			</React.Fragment>
		</BrowserRouter>
	</Root>,
	document.getElementById("root")
);
