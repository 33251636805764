import { NEWSLETTER } from "./actionTypes";

const initialState = {
    newsletter_response: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case NEWSLETTER:
            return { ...state, newsletter_response: action.payload };
        default:
            return state;
    }
}
