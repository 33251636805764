import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import axios from "axios";

import { CATEGORY_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class Categorywise extends Component {
    constructor(props){
		super(props);

		this.state = {            
			categories : {}
		}
    }
	
	componentDidMount() {		
	}
	
	
    componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }

	render() {
        
		const { categories } = this.props;
		return (
			<React.Fragment>
			<section className="slide-wrapper shop-by-category">
				<div className="slide-holder container">
					<div className="slider-title">
					  <h4 className="title-head">Categories</h4>
					  
						<h4 className="title-subhead">Shop By Category</h4>
						<div className="title-options">   
						</div>			  
					</div>
					<ul className="slider best-offer-slider ">
					{categories != '' ? 
						<React.Fragment>					
							{(categories.cat.map((item, index) =>
								<li className="list-item"  key={index}>
								<Link className=""  to={{
													pathname: "/products/"+item.id,
													state: {
														cat_name: item.service_name
													}
												}} >
									<div className="grid-card">
										<div className="grid-img-holder">
										  <img src={CATEGORY_IMAGE_URL + item.cat_image} alt={item.service_name} onError={(e)=>{e.target.src=DEFAULT_IMG}}/>
										</div>
										<div className="grid-title">{item.service_name}</div>
									</div>
								</Link>
								</li>									
							))}
						</React.Fragment> : ''
					}
					</ul>
					<div className="text-center"><a href="/products" className="btn" >View More</a></div>
				  </div>
				
            </section>    
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, { })(Categorywise);
