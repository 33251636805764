import { GET_LALAMOVE_FEE } from "./actionTypes";

const initialState = {
    lalamove_fees: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_LALAMOVE_FEE:
            return { ...state, lalamove_fees: action.payload };
        default:
            return state;
    }
}
