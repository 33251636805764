import React, { Component } from "react";

import Home from "./Home";

class Mobile extends Component {
   
    componentDidMount() {
        
    }    
    render() {
        return (
            <React.Fragment>
                <Home />
            </React.Fragment>
        );
    }
}

export default Mobile;
