import {
    GET_RESTAURANT_INFO,
    GET_RESTAURANT_INFO_BY_ID,
    GET_RESTAURANT_ITEMS,
    RESET_INFO,
    RESET_ITEMS,
    SINGLE_ITEM,
    SEARCH_ITEM,
    CLEAR_SEARCH,
    GET_CATEGORY,
    GET_BRAND,
	GET_CATEGORY_HOME,
	GET_SINGLE_CATEGORY_ITEMS,
	TOP_SHOPPING_OFFERS,
	SHOP_OUR_SERVICES,
	TODAY_DEALS,
	FASHION_OFFERS,
	FEATURED_PRODUCTS,
	TOP_OF_THE_DAY,
	GET_PRODUCT_BY_CATEGORY,
} from "./actionTypes";

const initialState = {
    restaurant_info: [],
    restaurant_items: [],
    single_item: [],
    get_category: [],
    get_brand: [],
	get_category_home : [],
    get_single_category_items: [],
    top_shopping_offers: [],
    shop_our_services: [],
    today_deals: [],
    fashion_offers: [],
    featured_products: [],
    top_of_the_day: [],
    get_product_by_category: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORY:
            return { ...state, get_category: action.payload };
        case GET_CATEGORY_HOME:
            return { ...state, get_category_home: action.payload };
        case GET_BRAND:
            return { ...state, get_brand: action.payload };
        case TOP_SHOPPING_OFFERS:
            return { ...state, top_shopping_offers: action.payload }; 
        case SHOP_OUR_SERVICES:
            return { ...state, shop_our_services: action.payload }; 
        case TODAY_DEALS:
            return { ...state, today_deals: action.payload }; 
        case FASHION_OFFERS:
            return { ...state, fashion_offers: action.payload }; 
        case FEATURED_PRODUCTS:
            return { ...state, featured_products: action.payload }; 
        case TOP_OF_THE_DAY:
            return { ...state, top_of_the_day: action.payload }; 
		case GET_PRODUCT_BY_CATEGORY:
            return { ...state, get_product_by_category: action.payload }; 
			
		case GET_SINGLE_CATEGORY_ITEMS:
            return { ...state, get_single_category_items: action.payload };
        case GET_RESTAURANT_INFO:
            return { ...state, restaurant_info: action.payload };
        case GET_RESTAURANT_INFO_BY_ID:
            return { ...state, restaurant_info: action.payload };
        case GET_RESTAURANT_ITEMS:
            return { ...state, restaurant_items: action.payload };
        case RESET_ITEMS:
            return { ...state, restaurant_items: action.payload };
        case RESET_INFO:
            return { ...state, restaurant_info: action.payload };
        case SINGLE_ITEM:
            return { ...state, single_item: action.payload };
        case SEARCH_ITEM:
            return { ...state, restaurant_items: action.payload };
        case CLEAR_SEARCH:
            return { ...state, restaurant_items: action.payload };
        default:
            return state;
    }
}
