import React, { Component } from "react";

import Mobile from "../../components/Mobile";
import { connect } from "react-redux";
// import { getSettings } from "../../services/settings/actions";
// import { getSingleLanguageData } from "../../services/languages/actions";

class App extends Component {
	componentDidMount() {
		// this.props.getSettings();
		// this.props.getSingleLanguageData(1);
	}

	render() {
		return (
			<React.Fragment>				
				<Mobile />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	// settings: state.settings.settings,
	// languages: state.languages.languages,
});
	

export default connect(	mapStateToProps,{
	// getSettings,
	// getSingleLanguageData,
})(App);
