import { WEBSITE_URL, IMAGE_URL } from "./website";

/* SPA URL Start */

export const key = "/X-API-KEY/mykey";
export const keyName = "X-API-KEY";
export const keyValue = "mykey";

export const GET_CATE_URL = WEBSITE_URL + "/Webservice_App/get_cat" + key;
export const GET_SETTINGS_URL = WEBSITE_URL + "/frontend/get_settings" + key;
export const GET_SINGLE_LANGUAGE_DATA_URL = WEBSITE_URL + "/frontend/get_label" + key;
export const GET_PROMO_SLIDER_URL = WEBSITE_URL + "/Webservice_App/get_all_homepage_slider" + key;
export const GET_PROMO_BANNER_URL = WEBSITE_URL + "/Webservice_App/get_all_homepage_slider2" + key;
export const GET_BRAND_URL = WEBSITE_URL + "/Webservice_App/get_all_brands" + key;
export const TOP_SHOPPING_OFFERS_URL = WEBSITE_URL + "/Webservice_App/get_top_shopping_offers" + key;
export const SHOP_OUR_SERVICES_URL = WEBSITE_URL + "/Webservice_App/get_service_cat" + key;
export const TODAY_DEALS_URL = WEBSITE_URL + "/Webservice_App/get_all_flash_deals" + key;
export const FASHION_OFFERS_URL = WEBSITE_URL + "/Webservice_App/get_fashion_offers" + key;
export const FEATURED_PRODUCTS_URL = WEBSITE_URL + "/Product/get_all_trending_product" + key;
export const TOP_OF_THE_DAY_URL = WEBSITE_URL + "/Webservice_App/get_top_of_the_day" + key;



export const GET_COURSE_DETAILS_URL = WEBSITE_URL + "/Webservice_App/course_details" + key;


export const GET_PRODUCT_DETAILS_URL = WEBSITE_URL + "/Product/get_single_product";


export const GET_PRODUCT_BY_CATEGORY_URL = WEBSITE_URL + "/Webservice_App/get_products_by_category";

export const LOGIN_USER_URL = WEBSITE_URL + "/Webservice_App/new_user_login";

export const REGISTER_USER_URL = WEBSITE_URL + "/Webservice_App/new_user_registration";

export const SAVE_ADDRESS_URL = WEBSITE_URL + "/Webservice_App/edit_user_account";
export const GET_ADDRESSES_URL = WEBSITE_URL + "/Webservice_App/get_user_account_details";
export const UPDATE_USER_INFO_URL = WEBSITE_URL + "/Webservice_App/get_user_account_info";

export const GET_ALL_PAYMENT_GATEWAYS_URL = WEBSITE_URL + "/Webservice_App/get_all_payment_gateways" + key;

export const PLACE_ORDER_URL = WEBSITE_URL + "/Neworders/add_new_booking";

export const GET_BRAND_ITEMS_URL = WEBSITE_URL + "/Product/get_all_products_by_brand";
export const GET_SUB_CATEGORY_ITEMS_URL = WEBSITE_URL + "/Product/get_all_product_subcat";

export const GET_ALL_PRODUCT_URL = WEBSITE_URL + "/Product/get_all_products_details";

export const GET_ALL_FLASH_DEAL_PRODUCT_URL = WEBSITE_URL + "/Webservice_App/get_all_flash_deal_products";

export const GET_ORDERS_URL = WEBSITE_URL + "/Webservice_App/userorders";

export const GET_BOOKING_URL = WEBSITE_URL + "/Webservice_App/userbooking";

export const GET_LIST_OF_SERVICES_URL = WEBSITE_URL + "/Webservice_App/get_list_of_services";
export const GET_CROWN_SERVICES_URL = WEBSITE_URL + "/Webservice_App/get_crown_services";
export const GET_PROMOTION_SERVICES_URL = WEBSITE_URL + "/Webservice_App/get_promotion_services";
export const GET_SERVICES_INFO_URL = WEBSITE_URL + "/Webservice_App/get_service_info";

export const BOOK_SERVICE_URL = WEBSITE_URL + "/Webservice_App/service_booking";


export const RATE_PRODUCT_URL = WEBSITE_URL + "/Webservice_App/rate_product";
export const GET_PRODUCT_REVIEW_URL = WEBSITE_URL + "/Webservice_App/get_all_product_reviews";

export const RATE_SERVICES_URL = WEBSITE_URL + "/Webservice_App/rate_services";
export const GET_SERVICES_REVIEW_URL = WEBSITE_URL + "/Webservice_App/get_all_services_reviews";



export const SEARCH_PRODUCT_URL = WEBSITE_URL + "/Webservice_App/searchproduct";

export const GET_USER_PACKAGES_URL = WEBSITE_URL + "/Webservice_App/userpackaging";

export const ENQUIRY_URL = WEBSITE_URL + "/Webservice_App/enquiry";

export const GET_GALLERY_IMAGE_URL = WEBSITE_URL + "/Webservice_App/gallery"+ key;
export const GET_CERTIFICATE_IMAGE_URL = WEBSITE_URL + "/Webservice_App/certificate"+ key;


export const CHANGE_PASSWORD_URL = WEBSITE_URL + "/Webservice_App/change_password";

export const UPDATE_USER_ACCOUNT_URL = WEBSITE_URL + "/Webservice_App/update_user_account";

export const SEND_PASSWORD_RESET_EMAIL_URL = WEBSITE_URL + "/Webservice_App/forgot_password";
export const GET_COUPON_INFO_URL = WEBSITE_URL + "/Webservice_App/get_coupon";

export const GET_PRODUCT_REDEMPTION_URL = WEBSITE_URL + "/Product/get_product_redemption"+ key;


export const GET_PAGE_CONTENT_URL = WEBSITE_URL + "/Webservice_App/get_page_content";

export const CHECK_STAFF_AVAILABILITY_URL = WEBSITE_URL + "/Webservice_App/check_staff_availability";


export const CREATE_NEW_SUPPORT_TICKET_URL = WEBSITE_URL + "/v2/Support/insert_new_support_ticket";
export const GET_ALL_SUPPORT_TICKETS_URL = WEBSITE_URL + "/v2/Support/get_all_support_tickets";
export const SINGLE_TICKET_DETAIL_URL = WEBSITE_URL + "/v2/Support/get_support_ticket_detail";
export const UPDATE_SUPPORT_TICKET_URL = WEBSITE_URL + "/v2/Support/update_support_ticket_details";



export const RUNNING_ORDERS_URL = WEBSITE_URL + "/Webservice_App/running_order";

export const RUNNING_USER_APPOINTMENT_URL = WEBSITE_URL + "/Webservice_App/user_appointment";

export const GET_TESTIMONIAL_CONTENT_URL = WEBSITE_URL + "/Webservice_App/get_testimonial_content";

 /* IMage Url */
 
 export const CATEGORY_IMAGE_URL = IMAGE_URL + "/uploads/";
 export const SUB_CATEGORY_IMAGE_URL = IMAGE_URL + "/uploads/sub_category/";
 export const ROOM_IMAGE_URL = IMAGE_URL + "/uploads/";
 export const PRODUCT_IMAGE_URL = IMAGE_URL + "/uploads/product_images/";
 export const BRAND_IMAGE_URL = IMAGE_URL + "/uploads/product_images/";
 export const SERVICES_IMAGE_URL = IMAGE_URL + "/uploads/service_images/";
 export const TESTIMONIAL_IMAGE_URL = IMAGE_URL + "/uploads/testimonials/";
 export const COURSE_IMAGE_URL = IMAGE_URL + "/uploads/course/";
 export const DEFAULT_IMG= IMAGE_URL + "/img/not-available.png"

/* SPA URL End */


export const SEARCH_LOCATIONS_URL = WEBSITE_URL + "/public/api/search-location";
export const GET_POPULAR_LOCATIONS_URL = WEBSITE_URL + "/public/api/popular-geo-locations";

export const GET_DELIVERY_RESTAURANTS_URL = WEBSITE_URL + "/public/api/get-delivery-restaurants";
export const GET_SELFPICKUP_RESTAURANTS_URL = WEBSITE_URL + "/public/api/get-selfpickup-restaurants";
export const GET_RESTAURANT_INFO_URL = WEBSITE_URL + "/public/api/get-restaurant-info";
export const GET_RESTAURANT_INFO_BY_ID_URL = WEBSITE_URL + "/public/api/get-restaurant-info-by-id";
export const GET_RESTAURANT_ITEMS_URL = WEBSITE_URL + "/public/api/get-restaurant-items";
export const APPLY_COUPON_URL = WEBSITE_URL + "/public/api/apply-coupon";

export const GET_PAGES_URL = WEBSITE_URL + "/public/api/get-pages";
export const GET_SINGLE_PAGE_URL = WEBSITE_URL + "/public/api/get-single-page";
export const SEARCH_RESTAURANTS_URL = WEBSITE_URL + "/public/api/search-restaurants";


export const DELETE_ADDRESS_URL = WEBSITE_URL + "/public/api/delete-address";

export const SET_DEFAULT_URL = WEBSITE_URL + "/public/api/set-default-address";
// export const GET_ORDERS_URL = WEBSITE_URL + "/public/api/get-orders";
export const GET_PAYMENT_GATEWAYS_URL = WEBSITE_URL + "/public/api/get-payment-gateways";
export const NOTIFICATION_TOKEN_URL = WEBSITE_URL + "/public/api/save-notification-token";
export const SEND_OTP_URL = WEBSITE_URL + "/public/api/send-otp";
export const VERIFY_OTP_URL = WEBSITE_URL + "/public/api/verify-otp";
export const RAZORPAY_PAYMENT_URL = WEBSITE_URL + "/public/api/payment/process-razor-pay/";
export const CHECK_USER_RUNNING_ORDER_URL = WEBSITE_URL + "/public/api/check-running-order";
export const GET_ORDER_CANCEL_URL = WEBSITE_URL + "/public/api/cancel-order";
export const GET_WALLET_TRANSACTIONS_URL = WEBSITE_URL + "/public/api/get-wallet-transactions";
export const CHECK_RESTAURANT_OPERATION_SERVICE_URL = WEBSITE_URL + "/public/api/check-restaurant-operation-service";
export const GET_SINGLE_ITEM_URL = WEBSITE_URL + "/public/api/get-single-item";
export const GET_ALL_LANGUAGES_URL = WEBSITE_URL + "/public/api/get-all-languages";

export const GET_ADDRESS_FROM_COORDINATES = WEBSITE_URL + "/public/api/coordinate-to-address";
export const GET_ADDRESS_TO_COORDINATES = WEBSITE_URL + "/public/api/address-to-coordinate";

export const VERIFY_PASSWORD_RESET_OTP_URL = WEBSITE_URL + "/public/api/verify-password-reset-otp";
export const CHANGE_USER_PASSWORD_URL = WEBSITE_URL + "/public/api/change-user-password";
export const GET_RESTAURANTS_CATEGORIES_URL = WEBSITE_URL + "/public/api/get-all-restaurants-categories";
export const GET_FILTERED_RESTAURANTS_URL = WEBSITE_URL + "/public/api/get-filtered-restaurants";
export const GET_RESTAURANTS_SLIDES_URL = WEBSITE_URL + "/public/api/get-restaurant-category-slides";
export const GET_ALERTS_URL = WEBSITE_URL + "/public/api/get-user-notifications";
export const MARK_ALL_NOTIFICATIONS_READ_URL = WEBSITE_URL + "/public/api/mark-all-notifications-read";
export const MARK_ONE_NOTIFICATION_READ_URL = WEBSITE_URL + "/public/api/mark-one-notification-read";
export const CHECK_CART_ITEMS_AVAILABILITY_URL = WEBSITE_URL + "/public/api/check-cart-items-availability";

export const GET_SINGLE_ORDER_TO_BE_RATED = WEBSITE_URL + "/public/api/single-ratable-order";
export const GET_RATABLE_ORDER_DETAILS_URL = WEBSITE_URL + "/public/api/get-ratable-order";
export const ADD_RATING_URL = WEBSITE_URL + "/public/api/save-new-rating";

/* Delivery URLs */
export const LOGIN_DELIVERY_USER_URL = WEBSITE_URL + "/public/api/delivery/login";
export const UPDATE_DELIVERY_USER_INFO_URL = WEBSITE_URL + "/public/api/delivery/update-user-info";
export const GET_DELIVERY_ORDERS_URL = WEBSITE_URL + "/public/api/delivery/get-delivery-orders";
export const GET_SINGLE_DELIVERY_ORDER_URL = WEBSITE_URL + "/public/api/delivery/get-single-delivery-order";
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL = WEBSITE_URL + "/public/api/delivery/set-delivery-guy-gps-location";
export const GET_DELIVERY_GUY_GPS_LOCATION_URL = WEBSITE_URL + "/public/api/delivery/get-delivery-guy-gps-location";
export const ACCEPT_TO_DELIVER_URL = WEBSITE_URL + "/public/api/delivery/accept-to-deliver";
export const PICKEDUP_ORDER_URL = WEBSITE_URL + "/public/api/delivery/pickedup-order";
export const DELIVER_ORDER_URL = WEBSITE_URL + "/public/api/delivery/deliver-order";



export const GET_LALAMOVE_FEE_URL = WEBSITE_URL + "/public/api/get-deliveryfee-lalamove";

export const NEWSLETTER_URL = WEBSITE_URL + "/public/api/newsletter";



export const GET_DELIVERY_RESTAURANTS_TOPOFFERS = WEBSITE_URL + "/public/api/get-delivery-restaurantstopoffers";

/*  Grocery URL NEW  */
export const GET_RESTAURANT_CATEGORY_ITEMS = WEBSITE_URL + "/public/api/get-restaurant-category-items";
export const GET_CATEGORY_URL = WEBSITE_URL + "/public/api/get-category";
export const GET_SINGLE_CATEGORY_ITEMS_URL = WEBSITE_URL + "/public/api/get-single-category-items";

export const GET_ITEMS_BY_PRICE_RANGE_URL = WEBSITE_URL + "/public/api/get-items-price-range";

export const GET_GIFT_CARD_URL = WEBSITE_URL + "/public/api/get-gift-card";

export const FREE_CART_GIFT_URL = WEBSITE_URL + "/public/api/free-cart-gift";

/* Product Request URL */

export const PRODUCT_URL = WEBSITE_URL + "/public/api/product-request";

export const GET_CASHBACK_OFFER_URL = WEBSITE_URL + "/public/api/get-cashback-offer";

export const GET_DELIVERY_TIME_URL = WEBSITE_URL + "/public/api/get-delivery-time";

export const GET_HOME_CATEGORY_ITEMS_URL = WEBSITE_URL + "/public/api/get-home-category-items";
export const GET_REFER_FRIEND_URL = WEBSITE_URL + "/public/api/get-refer-friend";
export const GET_EXPRESS_DELIVERY_ITEMS_URL = WEBSITE_URL + "/public/api/get-express-delivery-items";
export const GET_EXPRESS_DELIVERY_TIME_URL = WEBSITE_URL + "/public/api/get-express-delivery-time";
export const GET_CATEGORY_HOME_URL = WEBSITE_URL + "/public/api/get-category-home";

