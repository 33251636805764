import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { PRODUCT_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class TodayDeals extends Component {
    constructor(props){
		super(props);

		this.state = {
            update: true,
        responsive:{
            320: {
                items: 2,
            },
            575: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 1,
            },
            1199: {
                items: 1,
            },
                },
            }
    }
		
	
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { deals} = this.props;
		
		return (                        
			<React.Fragment>                         
				<div className="slide-wrapper deals col-12 col-lg-4 col-md-4">
					<div className="slide-holder">
					  <div className="slider-title">
						<h4 className="title-head">Current Deals</h4>
						<div className="title-options">
						  
						</div>

					  </div>
					  
					  <ul className="top-offers-list">


					  <OwlCarousel
                    className="owl-theme mt-20"
					nav={true}
					autoplay
					loop={false}
					dots={false}
					items={1} 
					rewind={true} 
                    responsive={this.state.responsive}                    
                >
                {deals.length === 0 ? (
						<ContentLoader
							height={170}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
							
						</ContentLoader>
                ) : ( deals.map((array, indx) =>  
							(array.map((item, index) =>  
								<React.Fragment key={index}>  
									<li  key={index}>
									<DelayLink to={"/deals/"+item.list_of_items_by_id} delay={50} >
										<div className="grid-card">  
											<div className="grid-img-holder">
										
												<img src={PRODUCT_IMAGE_URL + item.deal_image} alt={item.deal_title} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
										
											</div>
											<div className="item-name">{item.deal_title}</div>
											<div className="item-offer">{'Upto ' + item.discount+'% Off' }</div>
										</div>	
										</DelayLink>						   
									</li>									
								</React.Fragment>
							))
							))}
						</OwlCarousel>
					  </ul>
					
				  </div>
				</div>	                
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(TodayDeals);