import { PLACE_ORDER } from "./actionTypes";
import { BOOK_SERVICE } from "./actionTypes";

const initialState = {
    checkout: [],
    payservice: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case BOOK_SERVICE:
            return { ...state, payservice: action.payload };
        default:
            return state;
    }
}
