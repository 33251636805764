import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

class PromoBanner extends Component {

	constructor(props){
		super(props);

		this.state = {
            update: true,
        responsive:{
            320: {
                items: 1,
            },
            575: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 1,
            },
            1199: {
                items: 1,
            },
                },
            }
    }
	
	render() {
		const { slides } = this.props;
		// console.log(slides);
		return (
			<React.Fragment>

				<section className="mini-slider">
				<div className="">
					<OwlCarousel
						className="owl-theme"
						nav={true}
					autoplay
					loop={false}
					dots={false}
					items={2} 
					rewind={true} 
                    responsive={this.state.responsive}                 
						>
						{slides.length === 0 ? (
							<ContentLoader
								height={170}
								width={400}
								speed={1.2}
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
							>
								<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
								
							</ContentLoader>
						) : (
						
						slides.map(slide =>
								
								<div className="slide-item" key={slide.slide_id}>
									<img
										src={slide.slider_image}
										alt={slide.slider_text}
										className={`slider-wrapper__img slider-cust-img `}											
									/>
										
								</div>

								
						))}
					</OwlCarousel>	
				</div>
				</section>

				
			</React.Fragment>
		);
	}
}

export default PromoBanner;