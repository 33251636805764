import { GET_ORDERS, GET_BOOKINGS, CANCEL_ORDER } from "./actionTypes";

const initialState = {
    orders: [],
    booking: [],
    cancel: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ORDERS:
            return { ...state, orders: action.payload };
        case GET_BOOKINGS:
            return { ...state, booking: action.payload };
        case CANCEL_ORDER:
            return { ...state, cancel: action.payload };
        default:
            return state;
    }
}
