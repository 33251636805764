import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BRAND_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class Brand extends Component {

    constructor(props){
		super(props);
		this.state = {
            update: true,
        responsive:{
            320: {
                items: 2,
            },
            575: {
                items: 3,
            },
            768: {
                items: 4,
            },
            1024: {
                items: 5,
            },
            1199: {
                items: 8,
            },
                },
            }
    }
    componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }

	render() {
        const {brands} = this.props;
   
		return (
			<React.Fragment>                
				<section className="slide-wrapper featured-brand">
					<div className="slide-holder container">
						<div className="slider-title">
							<h4 className="title-head">Featured Brands</h4>
							<div className="title-options">
							</div>
						</div>
						<ul className="featured-brand-list">
						<OwlCarousel
                    className="owl-theme mt-20"
					nav={true}
					autoplay
					loop={false}
					dots={false}
					items={1} 
					rewind={true} 
                    responsive={this.state.responsive}                    
                >
                {brands.length === 0 ? (
						<ContentLoader
							height={170}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
							
						</ContentLoader>
                ) : (brands.map((item, index) =>  
								<React.Fragment key={index}>  
									<li  key={index}>
										<div className="grid-card">  
											<div className="grid-img-holder">
											<Link delay={50} to={{
													pathname: "/brands/"+item.brand_id,
													state: {
														cat_name: item.brand_name
													} }}>
												<img src={BRAND_IMAGE_URL + item.brand_image} alt={item.brand_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
											</Link>	
											</div>
										</div>						   
									</li>									
								</React.Fragment>
								))}
								</OwlCarousel>
						</ul>    
					</div>
				</section>   
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {})(Brand);